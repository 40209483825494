<template>
  <path
    d="M3 13C3 12.4477 3.44772 12 4 12H21C21.5523 12 22 12.4477 22 13V25C22 26.1046 21.1046 27 20 27H5C3.89543 27 3 26.1046 3 25V13Z"
    fill="#3769D6"
  />
  <path
    d="M11.3922 0.34314C12.0399 -0.11438 12.9602 -0.11438 13.6079 0.343142L23.3309 7.21131C24.64 8.136 23.8994 10 22.223 10H2.77696C1.10055 10 0.360038 8.136 1.66909 7.21131L11.3922 0.34314Z"
    fill="#92AFEA"
  />
  <circle cx="21.5" cy="15.5" r="8.5" fill="#EDF4FE" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M23.6297 14.8452H24.6191C25.0514 14.8452 25.2917 14.3452 25.0217 14.0076L21.9703 10.1935C21.764 9.93551 21.3716 9.93551 21.1653 10.1935L18.1139 14.0076C17.8439 14.3452 18.0842 14.8452 18.5165 14.8452H19.5059V18.969C19.5059 19.5384 19.9674 20 20.5368 20H22.5988C23.1682 20 23.6297 19.5384 23.6297 18.969V14.8452Z"
    fill="#3769D6"
  />
</template>
